<template>
  <div class="pa-0 ma-0">
    <v-menu
      :nudge-right="40"
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="displayDate"
          hint="yyyy-MM-dd"
          aut-date-input
          :disabled="disabled"
          @blur="setDate()"
        >
          <template v-slot:append>
            <v-icon v-on="on" v-bind="attrs" medium> mdi-calendar </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="dateMenu = false"
        :allowed-dates="allowedDates"
      ></v-date-picker>
    </v-menu>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          aut-time-input
          v-model="displayTime"
          dense
          @blur="setTime()"
          :rules="rules"
          hint="HH:mm"
          :disabled="disabled"
        >
          <template v-slot:append>
            <v-icon v-on="on" v-bind="attrs" medium>
              mdi-clock-time-four-outline
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-time-picker
        v-if="menu"
        :value="time"
        @input="time = $event"
        full-width
        @click:minute="menu = false"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
import { rules } from "@/rules";
import { setHours, setMinutes, set, endOfMonth } from "date-fns";
import { random } from "lodash";
export default {
  name: "TimeInput",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: String,
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: Boolean,
  },
  data() {
    return {
      menu: false,
      dateMenu: false,
      displayTime: "",
      displayDate: "",
    };
  },
  watch: {
    time() {
      this.setDisplayTime();
    },
    date() {
      this.setDisplayDate();
    },
  },
  computed: {
    date: {
      get() {
        const date = this.value || "";
        const formatDate = this.$options.filters.date;
        return formatDate(date);
      },
      set(value) {
        const dateTime = new Date(this.value || "");
        const [year, month, date] = value.split("-");
        const result = set(dateTime, {
          year,
          month: month - 1,
          date,
        }).toISOString();
        this.$emit("change", result);
      },
    },
    time: {
      get() {
        const time = this.value || "";
        const formatDate = this.$options.filters.date;
        return formatDate(time, { date_format: "HH:mm" });
      },
      set(time) {
        const date = new Date(this.value || "");
        const [hours, minutes] = time.split(":");
        const result = setMinutes(setHours(date, hours), minutes).toISOString();
        this.$emit("change", result);
      },
    },
  },
  mounted() {
    this.setDisplayTime();
    this.setDisplayDate();
  },
  methods: {
    allowedDates(val) {
      const appliedRules = [rules.not_past_date()];
      let result = true;
      for (var ruleToApply of appliedRules) {
        result = ruleToApply(val);
        if (result == true) {
          continue;
        }
        break;
      }
      return typeof result == "string" ? false : true;
    },
    setTime() {
      const timeInput = this.displayTime;

      if (timeInput == this.time) {
        return;
      }

      let [hours, minutes] = timeInput.split(":").map((value) => {
        const numValue = value * 1;
        if (!isNaN(numValue)) {
          return numValue;
        }
      });

      hours = hours <= 23 ? hours : random(1, 23);
      minutes = minutes <= 59 ? minutes : random(1, 59);

      this.time = `${hours}:${minutes}`;
    },
    setDate() {
      const dateInput = this.displayDate;

      if (dateInput == this.date) {
        return;
      }

      let [year, month, date] = dateInput.split("-").map((value) => {
        const numValue = value * 1;
        if (!isNaN(numValue)) {
          return numValue;
        }
      });

      const today = new Date();
      year = year < 9999 && year > 0 ? year : today.getFullYear();
      month = month <= 11 ? month : today.getMonth() + 1;

      const monthEnd = endOfMonth(new Date(year, month - 1, 1)).getDate();
      date = date <= monthEnd ? date : random(1, monthEnd);

      this.date = `${year}-${month}-${date}`;
    },
    setDisplayTime() {
      this.displayTime = this.time;
    },
    setDisplayDate() {
      this.displayDate = this.date;
    },
  },
};
</script>
